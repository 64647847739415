.grid {
    display: grid;
    justify-items: center;
}
.address-error{
    color:red;
}
.img_file {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 14px;
    gap: 9px;

    width: 62px;
    height: 62px;

    background: #F2F5FF;
    border: 1px dashed #583DFF;
    border-radius: 4px;

}

.div_listOption {
    display: flex;
    align-items: center;
}

.stations {
    margin-left: 22px;
    text-align: center;
    padding: 11px 15px;
    width: 465px;
    height: 62px;
    max-width: 465px;
    background: #F2F5FF;
    /* Purple */

    border: 1px dashed #583DFF;
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    margin: 0px 22px;


}

.flexXlFile {
    flex-direction: row-reverse;
    display: flex;
    margin-bottom: 15px;
    margin-top: 25px;
    width: 100%;
    max-width: 545px;
    height: 70px;
}

.overflow_point {
    max-width: 365px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflow_point_hidden {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

input {
    border: none;
    width: 100%;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.deliveryAddress {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 11px 15px;
    gap: 10px;
    /* position: absolute; */
    height: 71px;
    left: 0px;
    right: 0px;
    top: 10px;
    /* border: 1px solid #E1E1E8; */
    border-radius: 4px;
    margin-bottom: 20px;

}

.muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgba(0, 0, 0, 0.23);
}

.muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline {
    text-align: right;
    position: absolute;
    bottom: 0;
    left: 0;
    top: -5px;
    right: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgba(0, 0, 0, 0.23);
}

.h5StepNum1 {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    /* identical to box height, or 144% */


    letter-spacing: -0.66px;

    color: #81838C;

}

.infoSteps {
    height: 425px;
    width: 100%;
    max-width: 545px;
}

.head_steps_address {
    margin-right: 3px;
}


@media only screen and (max-width: 800px) {
    .infoSteps {
        height: 470px;
    }
}

@media only screen and (max-width: 650px) {
    .h1StepNum1 {
        text-align: center !important;

    }

    .h5StepNum1 {
        text-align: center;
    }

    .flexXlFile {
        width: 100%;
        max-width: 500px;
    }

    [dir='rtl'] .flexXlFile {
        flex-direction: row;
    }

    .infoSteps{
        max-width: 500px;
    }

    .stations {
     
        margin: 0px 0px;
        margin-left: 22px;
        max-width: 413px;
    }

    .overflow {
        width: 100%;
        max-width: 500px;
    }

    .deliveryAddress {
        /* overflow: auto; */
        height: 63px;
        width: 100%;
    }
}

.h1StepNum1 {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 800;
    font-size: 37px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -2px;

    color: #0D152E;

    text-align: right;
}

[dir='rtl'] .h1StepNum1 {

    text-align: left;
}

.moreDetails {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    /* identical to box height */
    padding: 12px;
    text-decoration-line: underline;

    color: #4F5976;
}

.moreDetailsWindow {
    background: #FFFFFF;
    border-radius: 4px;
    width: 350px;
    height: 300px;
    transform: translate(-26%, -160px);
    position: absolute;
}

.overflow {
    max-height: 150px;
    width: 100%;
    overflow-x: hidden;
    max-width: 545px;
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    overflow-x: hidden;
}

.pointClass {
    width: 220px;
    height: 19px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */


    color: #4F5976;
}

.span_destination {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height */

    text-align: right;

    color: #0D152E;

}

@media only screen and (max-width: 500px) {
    .deliveryAddress {
        width: 100%;
        height: 75px;
    }

    .infoSteps {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* padding: 0px; */
        gap: 10px;
        height: 518px;
        /* min-width: 336px; */
        width: 100%;

    }

    .head_steps_address {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 7px;
        width: 100%;
        /* min-width: 336px; */
        height: 60px;
    }


    .h1StepNum1 {
        font-size: 26px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -1.24px;
        color: #0D152E;
        /* min-width: 259px; */
        width: 100%;
    }

    .h5StepNum1 {
        font-size: 15px;
    }

    .flexXlFile {
        display: flex;
        flex-direction: row-reverse;
        /* align-items: center; */
        padding: 0px;
        /* gap: 18px; */
        justify-content: space-between;
        width: 100%;
        height: 62px;
        min-width: 300px;
        /* /* justify-content: center; */
    }

    .img_file {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 14px;
        /* gap: 9px; */
        width: 4%;
        min-width: 62px;
        height: 58px;
        background: #F2F5FF;
        /* border: 1px dashed #583DFF; */
        border-radius: 4px;
    }

    .stations {
        margin-left: 0px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 11px 23px 0px;
        gap: 10px;
        /* width: 256px; */
        height: 58px;
        background: #F2F5FF;
        border: 1px dashed #583DFF;
        border-radius: 4px;
        /* min-width: 256px; */
        align-content: center;
        width: 80%;
    }

    .mobile_autocomplete {
        width: 100%;
        height: 50px;
        /* min-width: 336px; */
        margin-bottom: 40px;
    }

    .moreDetailsHiiden {
        visibility: hidden;
    }

    .imgMoreDetailsHidden {
        visibility: hidden;
    }

    .details_btn {
        background-image: url(../../../../images/more-vertical.png);

        cursor: pointer;
        background-size: cover;
        width: 18px;
        height: 18px;

    }

    .moreDetails {
        text-decoration-line: none;

    }

    .div_listOption_mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 17px 18px;
        gap: 12px;
        position: absolute;
        width: 181px;
        height: 100px;
        left: 63px;
        /* top: -10px; */
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgb(0 0 0 / 11%);
        border-radius: 4px;
        align-items: center;

    }

    .moreDetails::before {
        content: '';
        background-image: url('../../../../images/Add.png');
        position: absolute;
        /* top: 0; */
        left: 4px;
        right: 8px;
        background-size: cover;
        width: 24px;
        height: 23px;

    }

    .imgMoreDetailsShow {

        width: 81px;
        height: 22px;

        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 31px;
        /* identical to box height */

        text-align: right;

        color: #FF0D38;

    }


    [dir='rtl'] .imgMoreDetailsShow {
        margin-left: 31px;
    }

    .overflow {
        max-height: 517px;
        width: 100%;
        margin-bottom: 22px;
    
    }


}