.MuiTextField-root {
    overflow-y: visible;
}

.h2HeaderMoreDetails {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 44px;
    /* identical to box height */
    text-align: right;
    letter-spacing: -2px;
    color: #0d152e;
}

.divPopUp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* margin-bottom: 35px; */
}

.muirtl-5wgg3p-MuiFormControl-root-MuiTextField-root {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 12px;
    border: 0;
    vertical-align: top;
    width: 213px;
}

.margin {
    /* margin: 20px; */
    /* height: 81px; */
    width: 100%;
    /* margin-bottom: 20px; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* max-width: 545px; */
}

.MuiDialog-root {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 44px;
    /* identical to box height */
    text-align: right;
    letter-spacing: -2px;
    color: #0d152e;
}

.moreDetailsHead {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 44px;
    /* identical to box height */
    height: 81px;
    text-align: right;
    letter-spacing: -2px;
    color: #0d152e;
}

.widthActions {
    width: 100%;
    max-width: 468px;
}

@-moz-document url-prefix() {
    .widthActions {
        max-width: 445px;
    }
}

.saveMoreDetails {
    width: 68%;
    height: 56px;
    background: #583dff;
    border-radius: 4px;
    /* margin: 0px 14px 0px 0px !important; */
}

.cancelMoreDetails {
    width: 26%;
    height: 56px;
    border-radius: 4px;
    border: 1px solid #583dff;
    /* margin-right: -4px; */
}

.popUp_flex {
    /* margin-bottom: 20px; */
    width: 100%;
    /* margin: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 545px;
}

.deliveryRadio {
    /* Frame 4150 */
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    width: 260px;
    height: 53px;
    background: #e9e6ff;
    border-radius: 7px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.FormControlLabel:checked {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 14px;
    gap: 5px;
    width: 125px;
    height: 43px;
    /* White */
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 7px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.radioStyle {}

.RadioSelectedText {
    width: 39px;
    height: 21px;
    font-family: "Heebo";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    /* Purple */
    color: #583dff;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.arrowUp {
    /* Vector */
    position: absolute;
    left: 0.67%;
    right: 5.33%;
    top: 93.21%;
    bottom: -79.61%;
    /* Purple */
    background: #583dff;
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.RadioNotSelectedText {
    /* width: 36px;
    height: 21px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px; */
    /* identical to box height */
    /* text-align: center; */
    /* Purple */
    /* color: #583DFF;
    flex: none;
    order: 0;
    flex-grow: 0; */
    width: 36px;
    height: 21px;
    font-family: "Heebo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    /* new/primary */
    color: #6b6bca;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.costumerName_width {
    width: 100%;
    height: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 545px;
}

.costumerDetails_width {
    width: 100%;
    height: 81px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 545px;
    margin-top:10px;
}

.mobile_width_popUp {
    width: 100%;
    height: 771px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-content: flex-end; */
    align-items: center;
}

.Arrow_cancel {
    width: 29px;
    left: 0;
    top: 31px;
    position: absolute;
    box-sizing: border-box;
    right: 12px;
    cursor: pointer;
}

[dir="rtl"] .Arrow_cancel {
    /* left: 24px; */
    right: 0;
    transform: scaleX(-1);
}

.cancel_mobile {
    /* min-width: 375px; */
    height: 80px;
    position: relative;
    width: 100%;
    background: #ffffff;
}
.muirtl-1wc848c-MuiFormHelperText-root.Mui-error{
    line-height: 10px;
}
.muiltr-1wc848c-MuiFormHelperText-root.Mui-error{
    line-height: 10px;
}
/* @media only screen and (max-width: 630px) {
.saveMoreDetails{
    margin: 0 !important;
}
} */

@media only screen and (max-width: 500px) {
    .divPopUp {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px;
        /* gap: 19px; */
        /* min-width: 336px; */
        height: 600px;
        justify-content: center;
        align-content: center;
    }
    .mobile_flex {
        display: flex !important;
        flex-direction: column !important;
        height: auto;
    }
    .mobile_width_popUp {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        position: relative;
        /* min-width: 350px; */
        width: 90%;
        height: 771px;
        background: #ffffff;
    }
    .h2HeaderMoreDetails {
        width: 146px;
        height: 34px;
        font-size: 26px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -1.24px;
        color: #0d152e;
    }
    .widthActions {
        width: 100%;
        margin-left: 0px;
    }
}