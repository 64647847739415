.back-to-top {
  width: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 30px;
  background: #583DFF;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  box-shadow: 0 5px 10px #ccc;
}
.logo_connect{
  /* width: 1600px; */
height: 70px;
background: #FFFFFF;
}

.widthContainer {

  padding: 0 !important;
}
/* *:not(html) {
  -webkit-transform: translate3d(0, 0, 0);
} */
/* .container{
  padding: 0;
} */
#root {
  overflow: hidden;
}

@media only screen and (max-width: 500px) {
  .back-to-top {
    width: 30px;

    font-size: 20px;

  }
  /* .logo_connect{
    position: fixed;
    z-index: 1000;
    width: 100%;
  } */

  .widthContainer {

    padding: 0px !important;
    /* height: 3680px; */
    width: 100%;
    max-width: 100%;
    background: #FFFFFF;
    /* min-width: 354px; */
  }
  .trackingStatusDisplay{
    justify-items: center;
    margin-top: 25px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap !important;
    width: 100%;
    height: 741px !important;
    background: #FFFFFF;
    max-height: 741px !important;
  }

}